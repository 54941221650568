import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    destination: { type: Object },
    waypoints: {type: Object},
    // optimizeWaypoints: true,
    // suppressMarkers: true,
    travelMode: { type: String },
  },

  afterCreate(directionsRenderer) {
    directionsRenderer.suppressMarkers = true
    let directionsService = new google.maps.DirectionsService();
    console.log('Iam Route ', this.origin, this.destination, this.travelMode, this.waypoints);
    (() => {
      let { origin, destination, travelMode, waypoints } = this;
      if (!origin || !destination || !travelMode) return;

      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode
        },
        (response, status) => {
          console.log('Iam Route ', response);
          if (status !== "OK") return;
          directionsRenderer.setDirections(response);
        }
      );
    })()
  }
});