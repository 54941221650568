import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
    apiKey: "AIzaSyDrbAbQ40F0iAr0ZNLraps-Sin7h3VEOqQ",
    authDomain: "winchit.firebaseapp.com",
    databaseURL: "https://winchit.firebaseio.com",
    projectId: "winchit",
    storageBucket: "winchit.appspot.com",
    messagingSenderId: "758443170170",
    appId: "1:758443170170:web:97423f7b453fdabe375ec3",
    measurementId: "G-SXDJHGFD6P"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDB = getDatabase(app);

export default firebaseDB;