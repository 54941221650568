
<template>
  <v-container>
    <v-row>

      <v-col cols="7">
        <v-container>
          <v-row>
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">Booking Details</div>
                </template>
                <v-card elevation="0" class="pa-2">
                  <template>
                    <v-form>

                      <v-row class="no-gutters py-2" style="margin-bottom: 25px">
                        <v-col md="5">
                          <h5>ID :</h5>
                          <v-text-field v-model="userDetails.id" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="4">
                          <h5>Type :</h5>
                          <v-text-field v-model="userDetails.type" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="3">
                          <h5>is Live :</h5>
                          <v-text-field v-model="userDetails.islive" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="5">
                          <h5>User :</h5>
                          <v-text-field v-model="userDetails.customer" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col v-if="userDetails.isKeyAtPickupLocation" md="3">
                          <h5>Keys At Pickup Location :</h5>
                          <v-text-field v-model="userDetails.isKeyAtPickupLocation" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>


                        <v-col v-if="userDetails.passengers" md="3">
                          <h5>Passenger :</h5>
                          <v-text-field v-model="userDetails.passengers" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="3">
                          <h5>Status :</h5>
                          <v-text-field v-model="userDetails.status" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Booking Date :</h5>
                          <v-text-field v-model="userDetails.date" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6" v-if="userDetails.pickupDate">
                          <h5>Pickup Date :</h5>
                          <v-text-field v-model="userDetails.pickupDate" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col v-if="userDetails.keypicupAddress" md="12">
                          <h5>Key Pick Address :</h5>
                          <v-text-field v-model="userDetails.keypicupAddress" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="12">
                          <h5>Pick up address :</h5>
                          <v-text-field v-model="userDetails.pickupAddress" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="12">
                          <h5>Drop Off Address :</h5>
                          <v-text-field v-model="userDetails.dropoffAddress" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Request Status :</h5>
                          <v-text-field v-model="userDetails.rideStatus" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Steering Status :</h5>
                          <v-text-field v-model="userDetails.steering_status" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Roll Status :</h5>
                          <v-text-field v-model="userDetails.roll_status" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Car Access :</h5>
                          <v-text-field v-model="userDetails.car_access" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Vehicle Make :</h5>
                          <v-text-field v-model="userDetails.vehicleMake" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="6">
                          <h5>Vehicle Registration :</h5>
                          <v-text-field v-model="userDetails.vehicleRegistration" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                        <v-col md="4">
                          <h5>Accepted Quote Price:</h5>
                          <v-text-field prefix="£" v-model="discountCodeData.quotedPrice" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="4">
                          <h5>Discount Code Used:</h5>
                          <v-text-field v-model="discountCodeData.discountCode" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>
                        <v-col md="4">
                          <h5>Amount Paid:</h5>
                          <v-text-field prefix="£" v-model="discountCodeData.paidPrice" class="purple-input"
                            style="margin-top: -14px; margin-right: 30px" readonly />
                        </v-col>

                      </v-row>

                    </v-form>
                  </template>

                </v-card>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">Booking Logs</div>
                </template>

                <v-virtual-scroll :items="requestLogs" height="728" item-height="100">
                  <template v-slot:default="{ item }">

                    <template style="margin: 25px;">

                      <v-card elevation="0" :class="item.user.role == 'Driver' ? 'professional' : 'customer'"
                        v-if="item.text"
                        v-bind:style="item.user.role == 'Driver' ? 'text-align: left; height:auto; border: 3px solid #C6C6C6;  max-width: 330px; ' : 'text-align: right; height:auto; border: 3px solid #C6C6C6; max-width:330px; '">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.text ? `Message From ${item.user.role} ${item.user.name}` :
                              getReadableAction(item) }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.text">{{ item.text }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ getReadableDate(item.createdAt) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>

                      <v-card v-else class="mx-auto" max-width="400" style=" border: 2px solid  #C6C6C6;">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.text ? `Message From ${item.user.role} ${item.user.name}` :
                              getReadableAction(item) }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.text">{{ item.text }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ getReadableDate(item.createdAt) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </template>


                  </template>
                </v-virtual-scroll>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

    </v-row>
    <v-row>

      <v-col cols="4">
        <v-container>
          <base-material-card color="primary">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Driver Location Logs</div>
            </template>
            <v-card elevation="0">
              <div style="max-width: 100%; height: 300px; position: relative;">
                <GmapMap :center="userDetails.locationCoordinates[0].position" :zoom="17" map-type-id="terrain"
                  style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
                  <GmapMarker :key="index" v-for="(m, index) in userDetails.locationCoordinates" :label='m.label'
                    :infoText='m.infoText' :position="m.position" :clickable="true" @click="center = m.position"
                    :size="2" />
                  <GmapCircle v-for="(pin, index) in markers" :key="index" :center="pin.position" :radius="6"
                    :visible="true" :options="{ fillColor: 'red', fillOpacity: 1.0 }" :size="2"></GmapCircle>
                  <GmapPolyline v-if="path.length > 0" :path="path" ref="polyline"></GmapPolyline>
                </GmapMap>
              </div>
            </v-card>
          </base-material-card>
        </v-container>
      </v-col>

      <v-col cols="8">
        <v-container>
          <v-row>
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">Booking Quotes</div>
                </template>
                <v-card elevation="0" style="max-height: 318px;">
                  <v-data-table :headers="headers" :items="quotesDetails" :loading="loading" class="elevation-1">
                    <template v-slot:[`item.createdAt`]="{ item }">
                      {{ getReadableDate(item.createdAt) }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip class="ma-2" label outlined small :color="getChipColor(item.status)">
                        {{ item.status }}
                      </v-chip>

                    </template>
                  </v-data-table>

                </v-card>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" v-if="userDetails && ['breakdown', 'RelocateNow'].includes(userDetails.type) && driversReceivedNotiication.length">
        <v-container>
          <v-row>
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">Notifications Sent to Drivers</div>
                </template>
                <v-card elevation="0" class="pa-2">
                  <v-data-table :headers="notificationsHeaders" :items="driversReceivedNotiication" :loading="loading" class="elevation-1">
                    <template v-slot:[`item.createdAt`]="{ item }">
                      {{ getReadableDate(item.createdAt) }}
                    </template>
                    <!-- <template v-slot:[`item.status`]="{ item }">
                      <v-chip class="ma-2" label outlined small :color="getChipColor(item.status)">
                        {{ item.status }}
                      </v-chip>

                    </template> -->

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip :key="index" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToProfile(item._id)">
                            mdi-eye
                          </v-icon>
                        </template>
                        View
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import { getRequest, getRequestLogs, getAllQuotesOnRequest, getDriverLcationLogsDuringRide, getRequestDriversReceivedNotifications } from "@/services/admin";
import Icons from "../dashboard/component/Icons.vue";
import VComponent from "../../components/base/VComponent.vue";
import DirectionRenderer from '@/components/DirectionRenderer';
import firebaseDB from "../../firebaseInit";
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

import { getDatabase, ref, onValue } from "firebase/database";
import Vue from 'vue'

Vue.component('dynamic-scroller', DynamicScroller)
Vue.component('dynamic-scroller-item', DynamicScrollerItem)
export default {
  components: { Icons, VComponent, DirectionRenderer },
  data: () => ({
    itemsScroll: Array.from({ length: 1000 }, (k, v) => v + 1),
    markers: null,
    userDetails: null,
    requestLogs: null,
    chatLogs: [],
    quotesDetails: [],
    driversReceivedNotiication: [],
    discountCodeData:{
      codeApplied: false,
      discountCode: 'N/A',
      quotedPrice: 'N/A',
      paidPrice: 'N/A',
    },

    // shape: {
    //         coords: [10, 10, 10, 15, 15, 15, 15, 10],
    //         type: 'poly'
    //       },
    notificationsHeaders: [
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: false,
        value: "surName",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: "Phone",
        align: "start",
        sortable: false,
        value: "phone",
      },
      // {
      //   text: "CreatedAt",
      //   align: "start",
      //   sortable: false,
      //   value: "createdAt",
      // },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'actions',
      },
    ],
    headers: [
      {
        text: "Driver",
        align: "start",
        sortable: false,
        value: "driver_name",
      },
      {
        text: "ETA",
        align: "start",
        sortable: false,
        value: "eta",
      },
      {
        text: "Proposed Time",
        align: "start",
        sortable: false,
        value: "proposed_new_time",
      },
      {
        text: "Quoted Price (£)",
        align: "start",
        sortable: false,
        value: "price",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
      }, {
        text: "CreatedAt",
        align: "start",
        sortable: false,
        value: "createdAt",
      }
    ],
    items: [
      {
        color: 'red lighten-2',
        icon: 'mdi-star',
      },
      {
        color: 'purple darken-1',
        icon: 'mdi-book-variant',
      },
      {
        color: 'green lighten-1',
        icon: 'mdi-airballoon',
      },
      {
        color: 'indigo',
        icon: 'mdi-buffer',
      },
    ],
    path: [],
  }),
  computed: {
    customDetails() {
      return {
        driver_name: `${this.quotesDetails.driverId.firstName}  ${this.quotesDetails.driverId.surName}`,
        eta: this.quotesDetails.ETA.time,
        proposed_new_time: this.quotesDetails.proposed_new_time && this.getReadableDate(this.quotesDetails.proposed_new_time),
        price: this.quotesDetails.price,
        status: this.quotesDetails.status,
        createdAt: this.quotesDetails.createdAt,
      };
    },
  },
  mounted() {
    getRequest(this.$route.params.id).then(async (res) => {
      this.userDetails = res.data[0];
      const selectedQuoted = res.data[0].selectedQuoteId;
      console.log('selectedQuotedselectedQuotedselectedQuotedselectedQuoted', selectedQuoted)
      if(selectedQuoted && selectedQuoted?.coupon){
        console.log('coupon appliedddd');
        this.discountCodeData.codeApplied = true;
        this.discountCodeData.discountCode = selectedQuoted.coupon.code;
        this.discountCodeData.quotedPrice = selectedQuoted.price;
        this.discountCodeData.paidPrice = selectedQuoted?.price_after_discount;
        
      }else{
        console.log('coupon NOT appliedddd');
        this.discountCodeData.quotedPrice = selectedQuoted?.price;
        this.discountCodeData.paidPrice = selectedQuoted?.price;

      }
      console.log('selectedQuoted', selectedQuoted);
      console.log("🚀 ~ file: Detail.vue:298 ~ getRequest ~ this.userDetails:", this.userDetails)
      const booking_date = new Date(this.userDetails.createdAt).toUTCString()
      const pickup_date = this.userDetails.dropoffDate && new Date(this.userDetails.dropoffDate).toUTCString()
      console.log('pickup_date', pickup_date, this.userDetails.dropoffDate, this.userDetails._id);
      const temp_result = {
        id: this.constructId(this.userDetails?._id),// this.userDetails._id,
        customer: this.userDetails?.customer ? `${this.userDetails?.customer?.firstName} ${this.userDetails?.customer?.surName}` : "Deleted User",
        islive: this.userDetails.status === 'active' ? 'Yes' : 'No',
        type: this.userDetails.requestType,
        pickupAddress: this.userDetails.pickup.address,
        dropoffAddress: this.userDetails.dropoff.address,
        status: this.userDetails.status,
        date: booking_date,
        pickupDate: pickup_date,
        firebase_id: this.userDetails.firebase_id,
        driverId: this.userDetails?.driverId,
        isKeyAtPickupLocation: this.userDetails.isKeyAtPickupLocation,
        locationCoordinates: [
          {
            position: {
              lat: this.userDetails.pickup?.location?.coordinates[1], lng: this.userDetails.pickup?.location?.coordinates[0],
            },
            label: this.userDetails.pickup?.location?.coordinates[1] ? "P" : "",
            infoText: this.userDetails.pickup?.location?.coordinates[1] ? "Ride Pickup Location" : "",
          },
          {
            position: {
              lat: this.userDetails.pickupKeys?.location?.coordinates[1], lng: this.userDetails.pickupKeys?.location?.coordinates[0],
            },
            label: (this.userDetails.pickup?.location?.coordinates[1] == this.userDetails.pickupKeys?.location?.coordinates[1]) ? "" : "K",
            infoText: (this.userDetails.pickup?.location?.coordinates[1] == this.userDetails.pickupKeys?.location?.coordinates[1]) ? "" : "Ride Key Pickup Location",
          },
          {
            position: {
              lat: this.userDetails.dropoff?.location?.coordinates[1], lng: this.userDetails.dropoff?.location?.coordinates[0],
            },
            label: this.userDetails.dropoff?.location?.coordinates[1] ? "D" : "",
            infoText: this.userDetails.dropoff?.location?.coordinates[1] ? "Ride DropOff Location" : "",
          }],
        rideStatus: this.userDetails.requestType == 'breakdown' ? this.getCorrectStatusTextBreakdown(this.userDetails) : this.getCorrectStatusTextRelocate(this.userDetails),
        steering_status: this.userDetails.steering_status,
        roll_status: this.userDetails.roll_status,
        car_access: this.userDetails.car_access,
        vehicleMake: this.userDetails.vehicleMake,
        vehicleRegistration: this.userDetails.vehicleRegistration,
      };
      console.log('temp_result', temp_result);
      if (this.userDetails.requestType !== "breakdown") {
        // temp_result.isKeyAtPickupLocation = this.userDetails.isKeyAtPickupLocation ? "Yes" : "No";
        temp_result.keypicupAddress = this.userDetails.pickupKeys.address ? this.userDetails.pickupKeys.address : null;

      }
      else {

        temp_result.passengers = this.userDetails.numberOfPassengers;
      }
      console.log(res, "single request!");

      this.userDetails = temp_result;
      this.constructChats(this.userDetails).then(firebaseChat => {
        console.log('firebaseChat', firebaseChat);
      })

      // console.log('locationCoordinates', this.userDetails.locationCoordinates);
    });

    getRequestLogs(this.$route.params.id).then((res) => {
      this.requestLogs = res.data.booking_logs.map(log => {
        log.id = log._id;
        return log;
      });
      console.log('ran2', this.$route.params.id);
    });

    getDriverLcationLogsDuringRide(this.$route.params.id).then((res) => {
      console.log('ran?');
      let tempMarkers = []
      res.data?.logs?.map(log => {
        tempMarkers.push({ position: { lat: log?.lat, lng: log?.lng } })
        this.path.push({ lat: log?.lat, lng: log?.lng })
      })
      this.markers = tempMarkers
      // console.log("🚀 ~ file: Detail.vue:340 ~ getDriverLcationLogsDuringRide ~ this.markers:", this.markers)
    });

    getRequestDriversReceivedNotifications(this.$route.params.id).then((res) => {
      this.driversReceivedNotiication = res.data.drivers;
    });

    getAllQuotesOnRequest({
      "requestId": this.$route.params.id
    }).then((res) => {
      let quotesResult = res.data.quotes;
      quotesResult.forEach(element => {
        let row = {
          driver_name: `${element.driverId.firstName}  ${element.driverId.surName}`,
          eta: element.ETA.time,
          price: element.price,
          status: element.status,
          createdAt: element.createdAt,
          proposed_new_time: element.proposed_new_time && this.getReadableDate(element.proposed_new_time),
        }
        this.quotesDetails.push(row)
      });
      console.log(this.quotesDetails, "sfsadfsa")

    });


  },
  methods: {

    getReadableDate(date) {
      const d = new Date(date).toUTCString();
      return d;
    },
    getReadableAction(item) {
      console.log('item', item);
      if (!item.actionUser)
        return item.action
      const actionUser = (item.actionUser == item.customerId) ? "Customer" : "Driver"
      return item.action + " by " + actionUser
    },
    goToQuotes(id) {
      this.$router.push({ name: "Quote list", params: { id: id } });
    },
    constructId(id) {
      return (id?.slice(17, 19) + "-" + id?.slice(19)).toUpperCase()
    },

    getCorrectStatusTextBreakdown(requestDetails) {
      console.log('called 1 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'Arrived') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },
    getCorrectStatusTextRelocate(requestDetails) {
      console.log('called 2 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'En route to key pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'Arrived at key pickup'
      } else if (requestDetails.status == 'keysPickedUp') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },
    async constructChats(request) {
      console.log("🚀 ~ Received file: Detail.vue:440 ~ constructChats ~ request:", request.firebase_id)
      let chatId = request.type == 'RelocateNow' ? this.$route.params.id : request.firebase_id
      console.log("🚀 ~ file: Detail.vue:445 ~ constructChats ~ letChatId:", chatId)

      const db = getDatabase();
      const chatRef = ref(db, `${process.env.VUE_APP_Path_Firebase ? process.env.VUE_APP_Path_Firebase : ''}` + '/chats/' + chatId);
      onValue(chatRef, (snapshot) => {
        console.log("🚀 ~ file: Detail.vue:538 ~ onValue ~ snapshot?.val():", snapshot?.val())
        if (snapshot?.val()) {
          const data = Object.values(snapshot.val());
          data.map((d, index) => {
            d.id = index
            d.user.role = (this.userDetails?.driverId._id == d.user._id) ? 'Driver' : 'Customer'
          })
          this.chatLogs = data;
          this.requestLogs = [...this.requestLogs, ...data]
          console.log("🚀 ~ file: Detail.vue:542 ~ onValue ~ this.requestLogs:", this.requestLogs)
          this.requestLogs.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log("🚀 ~ file: profile.vue:2086 ~ snapshot.forEach ~ childSnapshot.val():", data)
        }

      });
    },
    getMessage(string) {
      let final = "";
      if (item.text) {
        console.log("🚀 ~ file: Detail.vue:601 ~ getMessage ~ item.text:", item.text)
        final = (item.text).replace(/(.{30})/g, "$1\n")
        console.log("🚀 ~ file: Detail.vue:603 ~ getMessage ~ final:", final, final.length)
      }
      else {
        final = ""
      }
      return final
    },

    getChipColor(status) {
      switch (status) {
        case "accept":
          return "green";
        case "Rejected":
          return "red";
        case "Cancelled on request update":
          return "orange";
        case "Expired":
          return "gray";
        case "Cancelled":
          return "blue";
        case "pending":
          return "amber";
        default:
          return "white";
      }
    },

    goToProfile(id) {
      this.$router.push({ name: "Driver Profile", params: { id: id } });
    },
  },

};


</script>

<style>
.line-container {
  padding-left: 6rem !important;
}

.line-container .item-tag {
  width: 200px;
}

.customer {
  contain: initial;
  overflow: visible;
  width: 80%;
  float: right;
  margin-right: 13px;
}

.customer::before {
  position: absolute;
  content: "";
  color: #FFFFFF;
  top: 10px;
  transform: translateX(-100%);
  width: 10px;
  height: 13px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 13px solid #C6C6C6;
}

.professional {
  contain: initial;
  overflow: visible;
  width: 80%;
  float: left;
  margin-left: 13px;
}

.professional::before {
  position: absolute;
  content: "";
  color: #FFFFFF;
  top: 10px;
  transform: translateZ(100%);
  width: 10px;
  height: 13px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 13px solid #C6C6C6;
}

.professionalMes {
  float: right;
}

.customerMes {
  float: left;
}

.systemMes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroller {
  height: 100%;
}
</style>
